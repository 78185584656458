// ** React Imports
import React, { createContext, useContext, useState, useCallback, type ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
import {
  useIntl as useReactIntl,
  // FormattedMessage as ReactFormattedMessage,
  IntlProvider as ReactIntlProvider,
} from "react-intl"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { defaults, } from "conf/app"
import type { LanguageOptions, Languages, } from "conf/types/App"
import { flattenObject, } from "utility/helpers/object"
// ** Objects
// ** Styles
// ** Images

// ** FR resource
import frResource from "assets/locales/fr"
// ** EN resource
import enResource from "assets/locales/en"

const resources: Record<Languages, Record<"app" | "menu" | "messages" | "pathes", Record<string, string>>> = {
  fr: frResource,
  en: enResource,
}

// ** Menu msg obj
const translations: Record<Languages, Record<string, string>> = {
  fr: flattenObject(resources.fr) as Record<string, string>,
  en: flattenObject(resources.en) as Record<string, string>,
}

// ** Browser Detection
const storedLanguage = localStorage.getItem("language")
const defaultLanguage: Languages =
  storedLanguage !== "fr" && storedLanguage !== "en" ? defaults.defaultLanguage : storedLanguage

export type TType = (
  id: string,
  options?: {
    nameSpace: string
    defaultMessage?: string
  },
) => string

interface IntlContextType {
  changeLanguage: (lng: Languages) => void
  translatePath: (path: string, nameSpace: string) => string
  T: TType
  // formatMessage: (props: {
  //   id: string
  //   options?: {
  //     nameSpace: string
  //     defaultMessage?: string
  //   }
  // }) => JSX.Element
  language: Languages
  options: LanguageOptions
  languages: readonly string[]
}

const IntlContext = createContext<IntlContextType | null>(null)

const IntlProvider = ({ children, }: { children: ReactNode }): JSX.Element => {
  const [ language, setLanguage, ] = useState(defaultLanguage)
  const [ options, setOptions, ] = useState(defaults.languages[defaultLanguage])
  const [ messages, setMessages, ] = useState(translations[defaultLanguage])
  // const { formatMessage: formatMessageReactIntl, } = useReactIntl()

  const changeLanguage = useCallback((lang: Languages): void => {
    if (lang === language) return

    const revPathes: Record<string, string> = Object.keys(resources[language].pathes).reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        ...{ [resources[language].pathes[currentValue]]: currentValue, },
      }),
      {}
    )

    const translatedPath = window.location.pathname
      .split("/")
      .map(partial => translations[lang][`pathes.${revPathes[partial]}`])
      .join("/")

    setLanguage(lang)
    setMessages(translations[lang])
    setOptions(defaults.languages[lang])
    history.replaceState({}, "", translatedPath)
  }, [ language, ])

  const translatePath = (path: string, nameSpace: string = "menu"): string =>
    path
      .split("/")
      .map(partial => (partial === "" ? "" : T(partial, { nameSpace, })))
      .join("/")

  const T: TType = (id, options) => {
    const { formatMessage, } = useReactIntl()

    return formatMessage({
      id: `${options?.nameSpace ?? "app"}.${id}`,
      defaultMessage: options?.defaultMessage ?? id,
    })
    // return formatMessageReactIntl({
    //   id: `${options?.nameSpace ?? "app"}.${id}`,
    //   defaultMessage: options?.defaultMessage ?? id,
    // })
  }

  // const formatMessage = ({
  //   id,
  //   options,
  // }: {
  //   id: string
  //   options?: {
  //     nameSpace: string
  //     defaultMessage?: string
  //   }
  // }): JSX.Element => <ReactFormattedMessage />

  return (
    <IntlContext.Provider
      value={{
        changeLanguage,
        translatePath,
        T,
        // formatMessage,
        language,
        options,
        languages: Object.keys(defaults.languages),
      }}
    >
      <ReactIntlProvider
        key={language}
        locale={language}
        messages={messages}
        defaultLocale={defaultLanguage}
        onError={() => {}}
      >
        {children}
      </ReactIntlProvider>
    </IntlContext.Provider>
  )
}

const useIntl = (): IntlContextType => {
  const context = useContext(IntlContext)
  if (context === null) {
    throw new Error("useIntl must be used within a IntlProvider context")
  }
  return context
}

export { IntlProvider, useIntl, }
