// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
import "./style.scss"
// ** Images


interface ErrorBoundaryProps {
  error: Error
  // error?: {
  //   cause?: object
  //   columnNumber?: number
  //   fileName?: string
  //   lineNumber?: number
  //   message: string
  //   name: string
  //   stack: string
  // }
  resetErrorBoundary?: () => void
}

const ErrorBoundary = (props: ErrorBoundaryProps): JSX.Element => {
  const { error, resetErrorBoundary, } = props

  return (
    <div role="alert" className="alertymes">
      <h2>Something went wrong.</h2>
      {/* <details style={{ whiteSpace: "pre-wrap" }}> */}
      <details>
        <div className="mt-2">
          {error.cause !== undefined && <p>Cause: {JSON.stringify(error.cause, null, " ")}</p>}
          {/* { error?.fileName !== undefined && <p>File: {error.fileName}</p> } */}
          {/* { error?.lineNumber !== undefined && <p>Line: {error.lineNumber}</p> } */}
          {/* { error?.columnNumber !== undefined && <p>Column: {error.columnNumber}</p> } */}
        </div>
        <pre className="mt-2">{error.stack}</pre>
      </details>
      <button className="btn btn-gray btn-compact btn-hover-dark mt-3" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  )
}

export default ErrorBoundary
