// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import { FormattedMessage, } from "react-intl"
import { NavLink, } from "react-router-dom"
// ** Custom Components
import Carousel from "components/Carousel"
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
import "./hosting.scss"
// ** Images

const pathSlide1 = "/hosting/shared"
const pathSlide2 = "/hosting/wordpress"
const pathSlide3 = "/hosting/vps"
const pathSlide4 = "/hosting/housing"

const Slide1 = (): JSX.Element => (
  <div className="slide">
    <h1 className="mb-2 text-center">
      <FormattedMessage id="sharedHosting" />
    </h1>
    <p className="mb-3 text-center">
      <strong>
        <FormattedMessage id="trialOffer" />
        <em className="mx-2">
          <FormattedMessage id="free" values={{ gender: "female", }} />
        </em>
        <FormattedMessage id="and" />
        <em className="ms-2">
          <FormattedMessage id="unlimited" values={{ gender: "female", }} />
        </em>
      </strong>
    </p>
    <div className="d-flex">
      <NavLink className="btn btn-dark mt-3 w-50 mx-auto" to={pathSlide1}>
        <FormattedMessage id="try" />
      </NavLink>
    </div>
  </div>
)

const Slide2 = (): JSX.Element => (
  <div className="slide">
    <h1 className="mb-2 text-center">
      <FormattedMessage id="wordPressHosting" />
    </h1>
    <p className="mb-3 text-center">
      <strong>
        <FormattedMessage id="trialOffer" />
        <em className="mx-2">
          <FormattedMessage id="free" values={{ gender: "female", }} />
        </em>
        <FormattedMessage id="and" />
        <em className="ms-2">
          <FormattedMessage id="unlimited" values={{ gender: "female", }} />
        </em>
      </strong>
    </p>
    <div className="d-flex">
      <NavLink className="btn btn-dark mt-3 w-50 mx-auto" to={pathSlide2}>
        <FormattedMessage id="try" />
      </NavLink>
    </div>
  </div>
)

const Slide3 = (): JSX.Element => (
  <div className="slide">
    <h1 className="mb-2 text-center">
      <FormattedMessage id="vpsHosting" />
    </h1>
    <p className="mb-3 text-center">
      <strong>
        <FormattedMessage id="startsAt" />
        <em className="mx-2">5 €</em>
        <FormattedMessage id="month" />
      </strong>
    </p>
    <div className="d-flex">
      <NavLink className="btn btn-dark mt-3 w-50 mx-auto" to={pathSlide3}>
        <FormattedMessage id="order" />
      </NavLink>
    </div>
  </div>
)

const Slide4 = (): JSX.Element => (
  <div className="slide">
    <h1 className="mb-2 text-center">
      <FormattedMessage id="housing" />
    </h1>
    <p className="mb-3 text-center">
      <strong>
        <FormattedMessage id="startsAt" />
        <em className="mx-2">67 €</em>
        <FormattedMessage id="month" />
      </strong>
    </p>
    <div className="d-flex">
      <NavLink className="btn btn-dark mt-3 w-50 mx-auto" to={pathSlide4}>
        <FormattedMessage id="order" />
      </NavLink>
    </div>
  </div>
)

const Hosting = (): JSX.Element => (
  <Carousel
    id="hostingCarousel"
    controls={false}
    indicators={false}
    // fade={true}
    interval={2000}
    keyboard={false}
    pause="hover"
    wrap={true}
    touch={true}
    elements={[ { slide: <Slide1 />, }, { slide: <Slide2 />, }, { slide: <Slide3 />, }, { slide: <Slide4 />, }, ]}
  />
)

export default Hosting
