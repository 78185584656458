// ** React Imports
import React from "react"
// ** Store & Actions
// ** Third Party Components
import BootstrapCarousel, { type CarouselProps as BootstrapCarouselProps, } from "react-bootstrap/Carousel"
import BootstrapCarouselItem, {
  type CarouselItemProps as BootstrapCarouselItemProps,
} from "react-bootstrap/CarouselItem"
import BootstrapCarouselCaption, {
  type CarouselCaptionProps as BootstrapCarouselCaptionProps,
} from "react-bootstrap/CarouselCaption"
// ** Custom Components
// ** Hooks, context & utils
// ** Objects
// ** Styles
// ** Images


interface Item extends BootstrapCarouselItemProps {
  slide: React.ReactNode
  caption?: {
    title?: React.ReactNode
    message?: React.ReactNode
  } & BootstrapCarouselCaptionProps
}

interface CarouselProps extends BootstrapCarouselProps {
  id: string
  elements: Item[]
}

const Carousel = (props: CarouselProps): JSX.Element => {
  // ** Props
  const { id, elements, ...carouselOptions } = props

  return (
    <BootstrapCarousel id={id} {...carouselOptions}>
      {elements.map((element, index) => (
        <BootstrapCarouselItem key={index} interval={element.interval}>
          {element.slide}
          {element.caption !== undefined && (
            <BootstrapCarouselCaption>
              {element.caption.title !== undefined && <h3>{element.caption.title}</h3>}
              {element.caption.message !== undefined && <p>{element.caption.message}</p>}
            </BootstrapCarouselCaption>
          )}
        </BootstrapCarouselItem>
      ))}
    </BootstrapCarousel>
  )
}

export default Carousel
