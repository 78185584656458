// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type IntlRouteObject, } from "../types"
// ** Objects
// ** Styles
// ** Images

// lazy load all test views

const Animation = lazy(async () => import("views/tests/Animation"))
const ApiRequest = lazy(async () => import("views/tests/ApiRequest"))
const Blank = lazy(async () => import("views/tests/Blank"))
const Form = lazy(async () => import("views/tests/Form"))
const Hooks = lazy(async () => import("views/tests/hooks/Hooks"))
const Modals = lazy(async () => import("views/tests/Modals"))
const Notifications = lazy(async () => import("views/tests/Notifications"))
const Rating = lazy(async () => import("views/tests/Rating"))
const Spinner = lazy(async () => import("views/tests/Spinner"))
const Stepper = lazy(async () => import("views/tests/stepper/Stepper"))
const BasicTables = lazy(async () => import("views/tests/tables/basic/Basic"))
const AdvancedTables = lazy(async () => import("views/tests/tables/advanced/Advanced"))
const AllFeatures = lazy(async () => import("views/tests/tables/AllFeatures"))



// const ArrayColumnGroups = lazy(async () => import("views/tests/array/ArrayColumnGroups"))
// const ArrayColumnFiltering = lazy(async () => import("views/tests/array/ArrayColumnFiltering"))
// const ArrayColumnOrdering = lazy(async () => import("views/tests/array/ArrayColumnOrdering"))

// auth
// const Login = lazy(async () => import("views/tests/auth/components/Login"))
// const PersistLogin = lazy(async () => import("views/tests/auth/components/PersistLogin"))
// const RequireAuth = lazy(async () => import("views/tests/auth/components/RequireAuth"))
// const Home = lazy(async () => import("views/tests/auth/components/Home"))

const testsRoutes: IntlRouteObject[] = [
  {
    path: "hosting",
    children: [
      {
        path: "shared",
        element: <Blank />,
        handle: {
          // publicRoute: true,
          allowedRoles: { admin: true, },
        },
      },
    ],
  },
  {
    path: "tests",
    children: [
      {
        path: "animation",
        element: <Animation />,
        handle: {
          // publicRoute: true,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "api-request",
        element: <ApiRequest />,
        handle: {
          // publicRoute: true,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "blank",
        element: <Blank />,
        handle: {
          publicRoute: false,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "form",
        element: <Form />,
        handle: {
          publicRoute: true,
          // allowedRoles: { admin: true, },
        },
      },
      {
        path: "hooks",
        element: <Hooks />,
        handle: {
          publicRoute: false,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "modals",
        element: <Modals />,
        handle: {
          publicRoute: false,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "notifications",
        element: <Notifications />,
        handle: {
          publicRoute: false,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "rating",
        element: <Rating />,
        handle: {
          publicRoute: false,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "spinner",
        element: <Spinner />,
        handle: {
          publicRoute: false,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "stepper",
        element: <Stepper />,
        handle: {
          publicRoute: false,
          allowedRoles: { admin: true, },
        },
      },
      {
        path: "tables",
        children: [
          {
            path: "basic",
            element: <BasicTables />,
            handle: {
              publicRoute: false,
              allowedRoles: { admin: true, },
            },
          },
          {
            path: "advanced",
            element: <AdvancedTables />,
            handle: {
              publicRoute: false,
              allowedRoles: { admin: true, },
            },
          },
          {
            path: "all-features",
            element: <AllFeatures />,
            handle: {
              publicRoute: false,
              allowedRoles: { admin: true, },
            },
          },
          // {
          //   path: "column-groups",
          //   element: <ArrayColumnGroups />,
          //   handle: {
          //     publicRoute: false,
          //     allowedRoles: { admin: true, },
          //   },
          // },
          // {
          //   path: "column-filtering",
          //   element: <ArrayColumnFiltering />,
          //   handle: {
          //     publicRoute: false,
          //     allowedRoles: { admin: true, },
          //   },
          // },
          // {
          //   path: "column-ordering",
          //   element: <ArrayColumnOrdering />,
          //   handle: {
          //     publicRoute: false,
          //     allowedRoles: { admin: true, },
          //   },
          // },
        ],
      },




      //     {
      //       path: "auth",
      //       handle: {
      //         publicRoute: true,
      //       },
      //       children: [
      //         {
      //           path: "login",
      //           element: <Login />,
      //           handle: {
      //             publicRoute: true,
      //             fullPage: true,
      //           },
      //         },
      //         {
      //           path: "login",
      //           element: <Login />,
      //           handle: {
      //             publicRoute: true,
      //             fullPage: true,
      //           },
      //         },

      //         {
      //           path: "page1",
      //           element: <Login />,
      //           handle: {
      //             publicRoute: true,
      //           },
      //         },
      //         {
      //           path: "page2",
      //           element: <Login />,
      //           handle: {
      //             publicRoute: true,
      //           },
      //         },
      //         // {
      //         //   path: "signin",
      //         //   element: <Login />,
      //         //   handle: {
      //         //     title: "login",
      //         //     publicRoute: true,
      //         //     fullPage: true,
      //         //   },
      //         // },
      //         // {
      //         //   path: "",
      //         //   element: <PersistLogin />,
      //         //   handle: {
      //         //     publicRoute: true,
      //         //   },
      //         //   children: [
      //         //     {
      //         //       path: "",
      //         //       element: <RequireAuth allowedRoles={[ 5150, ]} />,
      //         //       handle: {
      //         //         publicRoute: true,
      //         //       },
      //         //       children: [
      //         //         {
      //         //           path: "home",
      //         //           element: <Home />,
      //         //           handle: {
      //         //             publicRoute: true,
      //         //           },
      //         //         },
      //         //       ],
      //         //     },
      //         //   ],
      //         // },
      //       ],
      //     },
      //     {
      //       path: "api-request",
      //       element: <ApiRequest />,
      //       handle: {
      //         publicRoute: false,
      //         allowedRoles: { admin: true, },
      //       },
      //     },
      //     {
      //       path: "hooks",
      //       element: <Hooks />,
      //       handle: {
      //         publicRoute: false,
      //         allowedRoles: { admin: true, },
      //       },
      //     },
      //     {
      //       path: "stepper",
      //       element: <Stepper />,
      //       handle: {
      //         publicRoute: false,
      //         allowedRoles: { admin: true, },
      //       },
      //     },
    ],
  },
]

export default testsRoutes
