// ** React Imports
import React, { lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
import VerticalLayout from "layouts/Vertical"
import HorizontalLayout from "layouts/Horizontal"
// ** Hooks, context & utils
import { useLayoutHandle, } from "./hooks"
import { useLayout, } from "utility/context/Layout"
import { useAuth, } from "utility/context/Auth"
import { useApp, } from "utility/context/App"
import { useIntl, } from "utility/context/Internationalization"
// ** Conf & helpers
import { LayoutType, } from "conf/types/Layout"
// ** Objects
// ** Styles
// ** Images

// lazy load login view
const Login = lazy(async () => import("views/account/Login"))

// const checkAuthorisation = (user: User | null, allowedRoles?: Role): boolean => {
//   if (allowedRoles === undefined) return true
//   if (user === null) return false
//   // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
//   return Object.keys(allowedRoles).some(el => allowedRoles[el as keyof typeof allowedRoles] && user.role[el as keyof typeof allowedRoles])
// }

const LayoutWrapper = (): JSX.Element => {
  const { connectedUser, } = useAuth()
  const layoutHandle = useLayoutHandle()
  const { settings, } = useLayout()
  const { T, } = useIntl()
  const { defaultPageTitle, } = useApp()

  if (layoutHandle.publicRoute !== true && connectedUser === null) {
    document.title = T("login")
    return <Login />
  }

  document.title = layoutHandle.title !== undefined ? T(layoutHandle.title) : defaultPageTitle
  if (layoutHandle.fullPage === true) return <></>

  if (settings.layout.type === LayoutType.LAYOUT_HORIZONTAL) return <HorizontalLayout />
  return <VerticalLayout />
}
export default LayoutWrapper
