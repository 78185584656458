// ** React Imports
import React, { Suspense, lazy, } from "react"
// ** Store & Actions
// ** Third Party Components
import { Container, } from "react-bootstrap"
import { Outlet, } from "react-router-dom"
// ** Custom Components
import FallbackSpinner from "components/spinner/FallbackSpinner"
// ** Hooks, context & utils
import { MenuProvider, } from "./menu/context/Menu"
// ** Conf & helpers
// ** Objects
// ** Styles
import "./scss/style.scss"
// ** Images

const Topbar = lazy(async () => import("./topbar/Topbar"))
const Menu = lazy(async () => import("./menu/Menu"))
const Footer = lazy(async () => import("./Footer"))
const RightSidebar = lazy(async () => import("./RightSidebar"))

const HorizontalLayout = (): JSX.Element => (
  <MenuProvider>
    <div className="wrapper">
      <Suspense fallback={<FallbackSpinner />}>
        <Topbar topbarDark={true} />
      </Suspense>

      <Suspense fallback={<FallbackSpinner />}>
        <Menu />
      </Suspense>

      <div className="content-page">
        <div className="content">
          <Container fluid>
            <Suspense fallback={<FallbackSpinner />}>
              <Outlet />
            </Suspense>
          </Container>
        </div>

        <Suspense fallback={<FallbackSpinner />}>
          <Footer />
        </Suspense>

        <Suspense fallback={<FallbackSpinner />}>
          <RightSidebar />
        </Suspense>
      </div>
    </div>
  </MenuProvider>
)

export default HorizontalLayout
