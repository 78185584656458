// ** React Imports
// ** Store & Actions
// ** Third Party Components
import { Slide, } from "react-toastify"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import {
  LayoutStyle,
  LayoutType,
  LayoutMode,
  MenuPosition,
  Theme,
  TopBarTheme,
  SideBarTheme,
  SideBarSize,
  type Layout,
} from "./types/Layout"
// ** Objects
// ** Styles
// ** Images

export const defaults: Layout = {
  layout: {
    style: LayoutStyle.LAYOUT_STYLE_DEFAULT,
    type: LayoutType.LAYOUT_VERTICAL,
    mode: LayoutMode.LAYOUT_WIDTH_FLUID,
    menuPosition: MenuPosition.MENU_POSITION_FIXED,
  },
  menu: {
    maxRootCount: {
      xs: 0,
      sm: 0,
      md: 0,
      lg: 6,
      xl: 7,
      xxl: 8,
    },
    keyPrefix: "menuItem",
    moreLabel: "more",
    showRootDividerHorizontalLayout: true,
    showRootTitlesHorizontalLayout: true,
  },
  theme: Theme.THEME_LIGHT,
  topbar: {
    theme: TopBarTheme.TOP_BAR_THEME_LIGHT,
    logo: true,
  },
  sidebar: {
    theme: SideBarTheme.SIDE_BAR_THEME_DARK,
    size: SideBarSize.SIDE_BAR_SIZE_DEFAULT,
    user: false,
  },
  // rightSidebar: false,
  scrollTop: true,
  routerTransition: {
    transition: "fadeIn",
  },
  carouselInterval: 5000,
  toastOptions: {
    position: "top-right", // 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left'
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: false,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    theme: "light", // 'light' | 'dark' | 'colored' | (string & {})
    transition: Slide, // Slide, Zoom, Flip, Bounce
  },
  gridBreakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
}
