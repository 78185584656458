// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images

interface Default {
  baseUrl: string
  timeout: number
  responseType: "arraybuffer" | "document" | "json" | "text" | "stream"
  responseEncoding: string
  publicKey: string
}

const config: Default = {
  // baseUrl: "https://api.calendeon.vm-101",
  baseUrl: "https://api.calendeon.com",
  timeout: 10,
  responseType: "json",
  responseEncoding: "utf8",
  publicKey:
    "-----BEGIN PUBLIC KEY-----\n" +
    "MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAz6rQyhiy81HmDZToavbM\n" +
    "tjCLukbP5q2DgmCQlWIy8WB/tRbP0U5RBwn0lJuU27tycMHH+nV1Yo0DpUOKpnY4\n" +
    "SarCC/nI4lH7WTBdQTKjVoTNJtxooWs52Z6Ksv/SduLCqdx58P+MOwtfWihl7fd8\n" +
    "zFvDSxQbqWQaGjhkV+Eu39ZD4EKpvSbCfxj5zrSyjCQkDELxnTD1Fw+D62SHfE9n\n" +
    "S6DXTGB6c4vooMhe5IdO8fhejtT0GrofnWsPvO5fgay58m6WuQ8dEtqL8U/qF4MH\n" +
    "9fJelxFp4gvhKaNVha63LmvwWpncWfU9hObDVS6J3eMuXNeRG30gYcEk9k8Pae5C\n" +
    "EmC7GtJcAoMs2HYQFYNM46uONNS5QiGsUq+D29g2DVOt/PMk4BvD3dzNW+320hQC\n" +
    "Jk5lb6E64sMiOlZzWVm5PT3MVjxL2UKfuICNAuk8jisuVuxB733OMvdbypvTwVSc\n" +
    "ir3zeUVg2U+D+KqRc34wjFOLeFwM7DkzoBN+1AJO+/GqYSnYIqSGQxlWaTj4whQ3\n" +
    "sKZf41EmjneJJ1WAPQA7jJ0E/5FFOT50UeC4U86zDcIHJGB09t6H66/33komGe4u\n" +
    "B+zDwmYHaFTLrv6tl0B/jcLPmplrUehvtzzqu9RMSS4pANmLiKDFUbHLF97eCgMI\n" +
    "cT9wwDhhaC+kXMTMrauNo5ECAwEAAQ==\n" +
    "-----END PUBLIC KEY-----\n",
}

export default config
