// ** React Imports
// ** Store & Actions
// ** Third Party Components
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
// ** Objects
// ** Styles
// ** Images


type basicType = string | number | boolean | null
type valueType = basicType | basicType[] | Record<string, unknown> | Array<Record<string, unknown>>

export default function useLocalStorage<T extends valueType>(key: string): [
  getValue: {
    (defaultValue?: undefined): T | null
    (defaultValue: T): T
  },
  setValue: (value?: T) => void,
  remove: () => void
] {
  function getValue(defaultValue?: T): T {
    const stored = localStorage.getItem(key)
    try {
      if (stored === null) throw new Error("")
      return JSON.parse(stored) as T
    } catch (error) {
      if (defaultValue === undefined) return null as T
      return defaultValue
    }
  }

  const setValue = (
    value?: T
  ): void => {
    if (value === undefined || value === null) remove()
    else localStorage.setItem(key, JSON.stringify(value))
  }

  const remove = (): void => {
    localStorage.removeItem(key)
  }

  return [ getValue, setValue, remove, ]
}