// ** React Imports
import React, { type ReactNode, createContext, useCallback, useContext, useState, useEffect, } from "react"
// import React, { type ReactNode, createContext, useCallback, useContext, useState, useEffect, useMemo, useRef, } from "react"
// ** Store & Actions
// ** Third Party Components
// import { useIntl as useReactIntl, } from "react-intl"
// import { useLocation, } from "react-router-dom"
// ** Custom Components
import FallbackSpinner from "components/spinner/FallbackSpinner"
// ** Hooks, context & utils
import useLocalStorage from "hooks/useLocalStorage"
import useViewport from "hooks/useViewport"
// ** Conf & helpers
// import { useAuth, } from "utility/context/Auth"
// import { useIntl, } from "utility/context/Internationalization"
import { LayoutType, type Layout, type Size, } from "conf/types/Layout"
import { defaults, } from "conf/layout"
// import { menuItems as defaultMenuItems,  } from "conf/menu"
import { changeHTMLAttribute, } from "utility/helpers/layout"
// import type { Menu as MenuType, MenuItem, MenuItemDescription, Group, GroupDescription, } from "conf/types/Menu"
// import { type AtLeastOneElementArray, } from "conf/types/Helpers"
// ** Objects
// ** Styles
// ** Images


const LocalStorageConfName = "theme"

interface LayoutContextType {
  settings: Layout
  updateSettings: (settings: Partial<Layout>) => void
  updateLayout: (settings: Partial<Layout["layout"]>) => void
  updateTopbar: (settings: Partial<Layout["topbar"]>) => void
  updateSidebar: (settings: Partial<Layout["sidebar"]>) => void
  reset: () => void
  disableLayer: {
    isVisible: boolean
    show: () => void
    hide: () => void
  }
  rightSideBar: {
    isVisible: boolean
    show: () => void
    hide: () => void
  }
  width: number
  height: number
  size: Size
}

const LayoutContext = createContext<LayoutContextType | null>(null)

const LayoutProvider = ({ children, }: { children: ReactNode }): JSX.Element => {
  type SavedLayout = Omit<
  Layout,
  "menu" | "topbar" | "scrollTop" | "routerTransition" | "carouselInterval" | "toastOptions" | "gridBreakpoints"
  > & { topbar: { theme: Layout["topbar"]["theme"] } }
  const { width, height, size, } = useViewport()
  const [ savedLayoutSettings, updateLayoutSettings, ] = useLocalStorage<Partial<SavedLayout>>(LocalStorageConfName)
 
  const [ settings, setSettings, ] = useState<Layout>({
    ...defaults,
    ...(savedLayoutSettings({}) as Layout),
  })

  const [ disableLayerVisible, setDisableLayerVisible, ] = useState(false)
  const [ rightSideBarVisible, setRightSideBarVisible, ] = useState(false)

  const updateSettings = useCallback(
    (newSettings: Partial<Layout>) => {
      setSettings(prev => ({ ...prev, ...newSettings, }))
    },
    [ setSettings, ]
  )

  const updateLayout = useCallback(
    (newLayout: Partial<Layout["layout"]>) => {
      if (newLayout.style !== undefined) changeHTMLAttribute("data-layout-style", newLayout.style)
      setSettings(prev => ({ ...prev, layout: { ...prev.layout, ...newLayout, }, }))
    },
    [ setSettings, ]
  )

  const updateTopbar = useCallback(
    (newTopbar: Partial<Layout["topbar"]>) => {
      setSettings(prev => ({ ...prev, topbar: { ...prev.topbar, ...newTopbar, }, }))
    },
    [ setSettings, ]
  )

  const updateSidebar = useCallback(
    (newSidebar: Partial<Layout["sidebar"]>) => {
      setSettings(prev => ({ ...prev, sidebar: { ...prev.sidebar, ...newSidebar, }, }))
    },
    [ setSettings, ]
  )

  const reset = useCallback(() => {
    setSettings(defaults)
  }, [ setSettings, ])

  useEffect(() => {
    updateLayoutSettings({
      layout: settings.layout,
      theme: settings.theme,
      topbar: { theme: settings.topbar.theme, },
      sidebar: settings.sidebar,
    })

    changeHTMLAttribute("data-layout-style", settings.layout.style)
    changeHTMLAttribute("data-layout", settings.layout.type)
    changeHTMLAttribute("data-layout-mode", settings.layout.mode)
    changeHTMLAttribute("data-layout-position", settings.layout.menuPosition)
    changeHTMLAttribute("data-bs-theme", settings.theme)
    changeHTMLAttribute("data-topbar-color", settings.topbar.theme)
    changeHTMLAttribute("data-menu-color", settings.sidebar.theme)
    changeHTMLAttribute(
      "data-sidenav-size",
      settings.layout.type === LayoutType.LAYOUT_VERTICAL ? settings.sidebar.size : null
    )
    changeHTMLAttribute(
      "data-sidenav-user",
      settings.layout.type === LayoutType.LAYOUT_VERTICAL ? settings.sidebar.user.toString() : null
    )
  }, [ settings, ])

  return (
    <LayoutContext.Provider
      value={{
        settings,
        updateSettings,
        updateLayout,
        updateTopbar,
        updateSidebar,
        reset,
        disableLayer: {
          isVisible: disableLayerVisible,
          show: useCallback(() => {
            setDisableLayerVisible(true)
          }, [ setDisableLayerVisible, ]),
          hide: useCallback(() => {
            setDisableLayerVisible(false)
          }, [ setDisableLayerVisible, ]),
        },
        rightSideBar: {
          isVisible: rightSideBarVisible,
          show: () => {
            setRightSideBarVisible(true)
          },
          hide: () => {
            setRightSideBarVisible(false)
          },
        },
        width,
        height,
        size,
      }}
    >
      {children}
      {disableLayerVisible && <FallbackSpinner className="global-disable-layer" />}
    </LayoutContext.Provider>
  )
}

const useLayout = (): LayoutContextType => {
  const context = useContext(LayoutContext)
  if (context === null) {
    throw new Error("useLayout must be used within an LayoutProvider context")
  }
  return context
}

export { LayoutProvider, useLayout, }
