// ** React Imports
import React, { createContext, useContext, useRef, useState, useEffect, type ReactNode, } from "react"
// ** Store & Actions
// ** Third Party Components
import { jwtDecode, type JwtHeader, } from "jwt-decode"
// ** Custom Components
// ** Hooks, context & utils
import useLocalStorage from "hooks/useLocalStorage"
import defaultConfig from "conf/api"
// ** Conf & helpers
import { type User, } from "conf/types/User"
// ** Objects
// ** Styles
// ** Images

export type JwtPayload = JwtHeader & { user: User }

interface AuthContextType {
  connectedUser: User | null
  setConnectedUser: React.Dispatch<React.SetStateAction<User | null>>
  persist: boolean
  setPersist: React.Dispatch<React.SetStateAction<boolean>>
  token: string | null
  setToken: (newToken: string | null) => void
}

const AuthContext = createContext<AuthContextType | null>(null)

const AuthProvider = ({ children, }: { children: ReactNode }): JSX.Element => {
  // function refreshToken(): Promise<User | null> {
  //   return new Promise<User | null>(resolve => {
    // return new Promise<User | null>(resolve => {
      // fetch(`${defaultConfig.baseUrl}/auth/refresh`, {
      //   method: "GET",
      //   cache: "no-cache",
      //   credentials: "include",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // })
      // .then(response => {
      //   resolve(null)
      // })
      // .catch(error => {
      //   resolve(null)
      // })
      // try {
      //   const response = await fetch(`${defaultConfig.baseUrl}/auth/refresh`, {
      //     method: "GET",
      //     cache: "no-cache",
      //     credentials: "include", // include, *same-origin, omit
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      // //     body: JSON.stringify(donnees),
      //   })
    
      //   // const resultat = await reponse.json()
      //   console.log("Réussite :", response.headers.get("authorization")) // eslint-disable-line no-console
      //   return null
      // } catch (erreur) {
      //   console.error("Erreur :", erreur) // eslint-disable-line no-console
      //   return null
      // }
  //   }
  // }

  // const refreshToken =  (): User | null => {
  //   const refreshToken = new Promise<User | null>((resolve, reject) => {
  //     fetch(`${defaultConfig.baseUrl}/auth/refresh`, {
  //       method: "GET",
  //       cache: "no-cache",
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     }).then(response => {
  //       const newToken = response.headers.get("authorization")
  //       if (newToken === null || newToken === "") resolve(null)
  //       else resolve(jwtDecode<JwtPayload>(newToken).user)
  //     }).catch(error => {
  //       console.error("Error:", error) // eslint-disable-line no-console
  //       reject(null)
  //     })
  //   }).then(user => user).catch(() => null)
  // }

  // const refreshToken = new Promise<string>((resolve) => {
  //   setTimeout(() => {
  //     resolve("toto")
  //   }, 300)
  // })



  // console.log(refreshToken) // eslint-disable-line no-console

  // const refreshToken = useCallback<() => Promise<User | null>>(async () => {
  //   try {
  //     const response = await fetch(`${defaultConfig.baseUrl}/auth/refresh`, {
  //       method: "GET",
  //       cache: "no-cache",
  //       credentials: "include", // include, *same-origin, omit
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //   //     body: JSON.stringify(donnees),
  //     })
  
  //     // const resultat = await reponse.json()
  //     console.log("Réussite :", response.headers.get("authorization")) // eslint-disable-line no-console
  //     return null
  //   } catch (erreur) {
  //     console.error("Erreur :", erreur) // eslint-disable-line no-console
  //     return null
  //   }
  // }, [])
    
  const [ connectedUser, setConnectedUser, ] = useState<User | null>(null)
  const [ getPersist, savePersist, ] = useLocalStorage<boolean>("persist")
  const [ persist, setPersist, ] = useState<boolean>(getPersist(false))
  const token = useRef<string | null>(null)

  const setToken = (newToken: string | null): void => {
    if (newToken === null) return
    if (newToken === "") setConnectedUser(null)
    else {
      const payload = jwtDecode<JwtPayload>(newToken)
      if (connectedUser === null || connectedUser.id !== payload.user.id) setConnectedUser(payload.user)
    }
  }

  useEffect(() => {
    if(!persist) return
    new Promise<User | null>(() => {
      fetch(`${defaultConfig.baseUrl}/auth/refresh`, {
        method: "GET",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(response => {
        setToken(response.headers.get("authorization"))
      }).catch(error => {
        console.error("Error:", error) // eslint-disable-line no-console
      })
    }).then(user => user).catch(() => null)
  }, [])

  useEffect(() => {
    savePersist(persist)
  }, [ persist, ])

  return (
    <AuthContext.Provider
      value={{
        connectedUser,
        setConnectedUser,
        persist,
        setPersist,
        token: token.current,
        setToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (context === null) {
    throw new Error("useAuth must be used within an AuthProvider context")
  }
  return context
}

export { AuthProvider, useAuth, }
