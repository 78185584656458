// ** React Imports
// ** Store & Actions
// ** Third Party Components
// import ReactGA from "react-ga"
// ** Custom Components
// ** Hooks, context & utils
// ** Conf & helpers
import { type Languages, type Language, } from "conf/types/App"
// ** Objects
// ** Styles
// ** Images
import frFlag from "assets/images/flags/french.jpg"
import enFlag from "assets/images/flags/us.jpg"
// import deFlag from "assets/images/flags/germany.jpg"
// import itFlag from "assets/images/flags/italy.jpg"
// import spFlag from "assets/images/flags/spain.jpg"
// import ruFlag from "assets/images/flags/russia.jpg"

// ReactGA.initialize("UA-216921674-1", {
//   // debug: true,
// })

interface Default {
  name: string
  defaultUrl: string
  defaultLanguage: Languages
  defaultPageTitle: string
  languages: Language
  offCanvasWidth: string
  googleMapsApiKey: string
  // ReactGA: typeof ReactGA
}

export const defaults: Default = {
  name: "Calendeon",
  // defaultUrl: "/settings/users",
  defaultUrl: "/tests/blank",
  defaultLanguage: "fr",
  defaultPageTitle: "Agenda",
  languages: {
    fr: { name: "Français", flag: frFlag, isRtl: false, locale: "fr-FR", },
    en: { name: "English", flag: enFlag, isRtl: false, locale: "en-US", },
  },
  offCanvasWidth: "1100px",
  googleMapsApiKey: "AIzaSyCQQWkSZKw2yEg0Q3g7Ogf-bcTnGhvTsh4",
  // ReactGA,
}
